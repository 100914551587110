import { collection, getDocs } from "firebase/firestore";
import { ref, getDownloadURL } from "firebase/storage";
import { db, storage } from "../firebase";

export const handleTermsClick = async (e) => {
  e.preventDefault();

  try {
    const docCollection = collection(db, "terms_conditions");
    const docSnap = await getDocs(docCollection);

    if (!docSnap.empty) {
      const docData = docSnap.docs[0].data();
      const filePath = docData.file;

      const storageRef = ref(storage, filePath);
      const pdfURL = await getDownloadURL(storageRef);

      window.open(pdfURL, "_blank");
    } else {
      console.log("No documents found in the collection!");
    }
  } catch (error) {
    console.error("Error fetching the document: ", error);
  }
};
