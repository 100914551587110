import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisH,
  faPhone,
  faEnvelope,
  faTimes,
} from "@fortawesome/free-solid-svg-icons"; // Importing the necessary icons
import "./FloatingButtonStyle.css"; // Import your custom CSS

function FloatingButton() {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <section id="floating_btn">
        {isOpen && <div className="overlay" onClick={handleClick}></div>}

        <div
          className={`floating-button ${isOpen ? "clicked" : ""}`}
          onClick={handleClick}
        >
          <FontAwesomeIcon icon={isOpen ? faTimes : faEllipsisH} />
        </div>

        {isOpen && (
          <div className="popup">
            <a href="tel:07904 966314" className="icon">
              <FontAwesomeIcon icon={faPhone} />
            </a>
            <a href="mailto:purmilla@cakelayers.co.uk" className="icon">
              <FontAwesomeIcon icon={faEnvelope} />
            </a>
          </div>
        )}
      </section>
    </>
  );
}

export default FloatingButton;
