// src/pages/Products.js
import "./CelebrationCakesStyle.css";
import "./SharedProductsStyles.css";

import { useCakeContext } from "../../context/CakeContext";
import Cake from "../../components/productsComponents/ProductCard";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

const CelebrationCakes = () => {
  const { cakes, loading, error } = useCakeContext();
  const navigate = useNavigate();

  const celebrationCakes = cakes.filter(
    (cake) => cake.category === "celebration"
  );

  const handleClick = (link) => {
    navigate(`/${link}`);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <HelmetProvider>
      <Helmet>
        <title>Celebration Cakes Portfolio | Cake Layers</title>
        <meta
          name="description"
          content="Explore our collection of celebration cakes at Cake Layers. Perfect for birthdays, anniversaries, and more. Discover beautifully crafted cakes for all your special moments."
        />
        <meta
          name="keywords"
          content="celebration cakes, birthday cakes, anniversary cakes, Cake Layers, custom cakes, special occasion cakes"
        />
        <meta
          property="og:title"
          content="Celebration Cakes Portfolio | Cake Layers"
        />
        <meta
          property="og:description"
          content="Explore our collection of celebration cakes at Cake Layers. Perfect for birthdays, anniversaries, and more. Discover beautifully crafted cakes for all your special moments."
        />
        <meta
          property="og:url"
          content="https://cakelayers.web.app/celebration-cakes"
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <section>
        <div className="product_hero_container celebration_txt_cont">
          <h1>Celebration Cakes Portfolio</h1>
          <p id="hero_subtitle">Explore Our Finest Cake Creations</p>
          <p id="hero_paragraph">
            We are passionate about crafting beautiful celebration cakes that
            make every occasion memorable. Whether for a birthday, anniversary,
            engagement, christening, retirement, or any other special
            celebration, you will be delighted!
          </p>
          <button className="brownBtn" onClick={() => handleClick("contact")}>
            Enquire
          </button>
        </div>
      </section>
      <section id="product_gallery_cont">
        <div className="product_gallery">
          {celebrationCakes.map((product) => (
            <Cake key={product.id} product={product} />
          ))}
        </div>
      </section>
    </HelmetProvider>
  );
};

export default CelebrationCakes;
