import "./SharedProductsStyles.css";
import React, { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import Cake from "../../components/productsComponents/ProductCard";
import {
  db,
  storage,
  collection,
  getDocs,
  ref,
  getDownloadURL,
} from "../../firebase";
const Desserts = () => {
  const [desserts, setDesserts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDesserts = async () => {
      try {
        const dessertsCollection = collection(db, "desserts");
        const dessertsSnapshot = await getDocs(dessertsCollection);
        const dessertsList = await Promise.all(
          dessertsSnapshot.docs.map(async (doc) => {
            const data = doc.data();
            const storageRef = ref(storage, data.image);

            const imageURL = await getDownloadURL(storageRef);
            return {
              id: doc.id,
              name: data.name,
              image: imageURL,
              order: data.order !== undefined ? data.order : Infinity,
            };
          })
        );
        dessertsList.sort((a, b) => a.order - b.order);

        setDesserts(dessertsList);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchDesserts();
  }, []);
  const handleClick = (link) => {
    navigate(`/${link}`);
  };
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <HelmetProvider>
      <Helmet>
        <title>Desserts Portfolio | Cake Layers</title>
        <meta
          name="description"
          content="Indulge in pure elegance with our meticulously crafted desserts. Explore our range of handmade macarons, cupcakes, cakepops, perfect for any celebration."
        />
        <meta
          name="keywords"
          content="desserts, macarons, cupcakes, cakepops, Cake Layers"
        />
        <meta property="og:title" content="Desserts Portfolio | Cake Layers" />
        <meta
          property="og:description"
          content="Indulge in pure elegance with our meticulously crafted desserts. Explore our range of handmade macarons, cupcakes, cakepops, perfect for any celebration."
        />
        <meta property="og:url" content="https://cakelayers.web.app/desserts" />{" "}
        <meta property="og:type" content="website" />
      </Helmet>
      <section>
        <div className="product_hero_container celebration_txt_cont">
          <h1>Desserts Portfolio</h1>
          <p id="hero_subtitle">
            Indulge in pure elegance with our meticulously crafted macarons,
            <br />
            where each bite is a testament to refined taste and sophistication
          </p>
          <p id="hero_paragraph">
            Our delicate small are a fun and sweet treat for everyone to enjoy.
            Ideal for gifting, sharing with loved ones, or enhancing your
            dessert table display. We offer macarons, cakepops, cakesicles, and
            mini chocolate Christmas puddings to perfectly complement your
            special celebration.
          </p>
          <button className="brownBtn" onClick={() => handleClick("contact")}>
            Enquire
          </button>
        </div>
      </section>
      <section id="product_gallery_cont">
        <div className="product_gallery">
          {desserts.map((product) => (
            <Cake key={product.id} product={product} />
          ))}
        </div>
      </section>
    </HelmetProvider>
  );
};

export default Desserts;
