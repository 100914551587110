function TestimonialWithImage({ testimonial }) {
  return (
    <div className="otr_testimonial_cont">
      <div className="inner_testimonial_cont">
        <div className=" testimonial_txt_cont">
          <div className="testimonial_txt text_container">
            <svg
              className="quote-icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 300 300"
            >
              <g transform="rotate(180 150 150)">
                <path d="M103.23 145.98c-24.42-11.67-29.98-44.15-10.47-62.91 4.5-4.97 15.66-9.15 10.79-17.58-2.29-3.84-7.25-5.03-11.05-2.67-40.13 28.21-76.9 64.95-68.47 129.87 7.69 59.24 105.24 63.52 107.08-2.53 0-19.63-11.51-36.35-27.88-44.18zm146.08 0c-24.42-11.67-29.98-44.15-10.47-62.91 4.5-4.97 15.66-9.15 10.79-17.58-2.29-3.84-7.25-5.03-11.05-2.67-40.13 28.21-76.9 64.95-68.47 129.87 7.69 59.24 105.24 63.52 107.08-2.53 0-19.63-11.51-36.35-27.88-44.18z"></path>
              </g>
            </svg>
            <h3>{testimonial.name}</h3>
            <p>{testimonial.description}</p>
          </div>
        </div>
        <div className="testimonial_img_cont">
          <div className="testimonial_img">
            <img src={testimonial.image}></img>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TestimonialWithImage;
