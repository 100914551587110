import { BrowserRouter, Routest, Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";
import WeddingCakes from "./pages/Products/WeddingCakes";
import CelebrationCakes from "./pages/Products/CelebrationCakes";
import CorporateProducts from "./pages/Products/CorporateProducts";
import LadooPreviewPage from "./pages/LadooPreviewBox/LadooPreviewPage";
import CakeLadoo from "./pages/Products/CakeLadoo";
import Desserts from "./pages/Products/Desserts";
import Cupcakes from "./pages/Products/Cupcakes";
import FlavourInformation from "./pages/Information/FlavourInformation";
import Testimonials from "./pages/Information/Testimonials";
import PricingInformation from "./pages/Information/PricingInformation";
import MaintenancePage from "./maintenance/MaintenancePage";

// import Information from "./pages/Information/Information";
import NotFound from "./pages/NoPage/404";
import Header from "./components/headerComponent/Header";
import Footer from "./components/footerComponent/Footer";
import FloatingButton from "./components/floatingBtnComponent/FloatingButton";
import ScrollToTopButton from "./components/ScrollToTopButtonComponent/ScrollToTopButton";
import AutoScrollToTop from "./components/AutoScrollToTopComponent/AutoScrollToTop";

import { CakeProvider } from "./context/CakeContext"; // Context API
import { CartProvider } from "./context/CartContext";
import { LadooProvider } from "./context/LadooContext";
// Import Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect } from "react";

export default function App() {
  // useEffect(() => {
  //   (async () => {
  //     const LocomotiveScroll = (await import("locomotive-scroll")).default;
  //     const scroll = new LocomotiveScroll({
  //       el: document.querySelector("[data-scroll-container]"),
  //       smooth: true,
  //     });
  //     // Store scroll instance globally or use context
  //     window.locomotiveScroll = scroll;
  //   })();
  // }, []);

  return (
    <div>
      <BrowserRouter>
        <CartProvider>
          <CakeProvider>
            <LadooProvider>
              <Header></Header>
              <AutoScrollToTop />

              <Routes>
                <Route index element={<Home />} />
                {/* <Route path="/" element={<InitialLoadPage />} /> */}
                <Route path="/home" element={<Home />} />
                <Route path="/about" element={<About />} />

                <Route path="/flavours" element={<FlavourInformation />} />
                <Route path="/pricing" element={<PricingInformation />} />
                <Route path="/testimonials" element={<Testimonials />} />

                <Route path="cake-ladoo" element={<CakeLadoo />} />
                <Route path="cake-Ladoo-Order" element={<LadooPreviewPage />} />
                <Route path="wedding-cakes" element={<WeddingCakes />} />
                <Route
                  path="celebration-cakes"
                  element={<CelebrationCakes />}
                />
                <Route path="corporate" element={<CorporateProducts />} />
                <Route path="cupcakes" element={<Cupcakes />} />
                <Route path="desserts" element={<Desserts />} />
                <Route path="corporate" />
                <Route path="/contact" element={<Contact />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
              {/* <MaintenancePage /> */}
              <ScrollToTopButton />
              <FloatingButton />
              <Footer />
            </LadooProvider>
          </CakeProvider>
        </CartProvider>
      </BrowserRouter>
    </div>
  );
}
