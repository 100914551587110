import "./HomeStyle.css";
import cake_banner_img1 from "../../assets/cake_banner_img1.jpg"; // with import
import ladoo_img from "../../assets/ladooHeroImg.jpg";
import commitment_img from "../../assets/commitment_img.jpg";
import explore_flavours_img from "../../assets/explore_flavours_img.jpg";

import { useNavigate } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

export default function Home() {
  const navigate = useNavigate();

  const handleClick = (link) => {
    navigate(`/${link}`);
  };

  return (
    <HelmetProvider>
      <Helmet>
        <title>Artisanal Cakes & Desserts | Cake Layers</title>
        <meta
          name="description"
          content="Explore luxury custom cakes for weddings and special occasions. Cake Layers offers exquisite designs and flavors."
        />
        <meta
          name="keywords"
          content="cakes, wedding cakes, custom cakes, luxury cakes, special occasion cakes, cake ladoo, ladoo"
        />
        <meta
          property="og:title"
          content="Artisanal Cakes & Desserts | Cake Layers"
        />
        <meta
          property="og:description"
          content="Explore luxury custom cakes for weddings and special occasions. Cake Layers offers exquisite designs and flavors."
        />
        <meta property="og:image" content={cake_banner_img1} />
        <meta property="og:url" content="https://cakelayers.web.app/" />
        <meta property="og:type" content="website" />
      </Helmet>
      <div>
        <div className="hero_outer_container">
          <div className="parallax">{/* <img src={hero_img} /> */}</div>
          <div className="title_container">
            <div className="hero_title text_container">
              <h1>LUXURY CAKES</h1>
            </div>
            {/* ############ */}
            <div className="hero_subtitle hero_para_new text_container">
              <svg
                className="line-top"
                xmlns="http://www.w3.org/2000/svg"
                width="100"
                height="2"
                viewBox="0 0 100 2"
              >
                <line
                  x1="0"
                  y1="1"
                  x2="65"
                  y2="1"
                  stroke="#6a363b"
                  strokeWidth="2"
                />
              </svg>
              <p>Elegant, opulent, and always indulgent</p>
              <div className="second_line">
                <svg
                  className="line-bottom"
                  xmlns="http://www.w3.org/2000/svg"
                  width="160"
                  height="2"
                  viewBox="0 0 200 2"
                >
                  <line
                    x1="0"
                    y1="1"
                    x2="200"
                    y2="1"
                    stroke="#6a363b"
                    strokeWidth="2"
                  />
                </svg>
              </div>
            </div>
            {/* ############## */}
            <div className="hero_para_new">
              <p>
                An exquisite cake experience using quality ingredients to
                celebrate life's sweetest moments.
              </p>
              <p>
                <span>
                  Discover Cake Ladoo: A contemporary twist on traditional South
                  Asian sweets
                </span>
              </p>
            </div>

            <button
              className="brownBtn home_hero_btn"
              onClick={() => handleClick("contact")}
            >
              CONTACT ME
            </button>
          </div>
        </div>

        {/* ################################################################################ */}

        <section className="cake_feature_banner">
          <div className="otr_cont_cake">
            <div className="cake_txt_cont text_container">
              <h2>Crafting Excellence In Every Cake</h2>
              <p>
                With over 15 years of baking experience, Cake Layers is
                dedicated to ensuring you get a professional yet friendly
                service from the moment you contact us. Whether you are looking
                for a simple elegant cake or a lavish design, you will feel at
                ease knowing we will understand your style to create the
                stunning masterpiece you desire.
                <br />
                <br />
                Based in the West Midlands, we cover all surrounding areas,
                London and Manchester.
              </p>
              <button
                className="pinkBtn"
                onClick={() => handleClick("wedding-cakes")}
              >
                PORTFOLIO
              </button>
            </div>

            <div className="cake_feature_image_container">
              <img
                src={cake_banner_img1}
                alt="Elegant 5-tier square cake with 2nd and 4th tiers made of transparent glass, showcasing flowers inside."
                className="image"
              />
            </div>
          </div>
        </section>

        <section className="ladoo_section">
          <div className="main_ladoo_container">
            <div className="ladoo_content outer_ladoo_img_cont">
              <img src={ladoo_img} />
            </div>
            <div className="ladoo_content outer_ladoo_text_container">
              <div className="inner_ladoo_text_container text_container">
                <h2>
                  Discover The Delight
                  <br />
                  Of Cake Ladoo
                </h2>
                <p id="subtitle_ladoo">
                  The Perfect Fusion of Tradition and Indulgence
                </p>
                <p>
                  Embark on a new journey of taste in South Asian sweets. Cake
                  Ladoo offers an innovative take on the beloved, traditional
                  ladoo’ with unique flavours and artistic flair. Presented in
                  our beautiful bespoke boxes, Cake Ladoo will certainly elevate
                  your celebration.
                </p>
                <button
                  className="pinkBtn"
                  onClick={() => handleClick("cake-ladoo")}
                >
                  PORTFOLIO
                </button>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="cards_container">
            <div className="cards_text_container">
              <div className="text_container">
                <h2>More to Explore</h2>
              </div>
            </div>
            <div className="cards_link_container">
              <div onClick={() => handleClick("flavours")} className="crd ">
                <div className="crd_content crd_flavours"></div>
                <div className="crd_label">
                  <p>FLAVOURS</p>
                </div>
              </div>
              <div onClick={() => handleClick("testimonials")} className="crd">
                <div className="crd_content">
                  <p>Hear from Our Delighted Customers!</p>
                </div>
                <div className="crd_label">
                  <p>TESTIMONIALS</p>
                </div>
              </div>
              <div onClick={() => handleClick("cupcakes")} className="crd">
                <div className="crd_content crd_cupcakes"></div>
                <div className="crd_label">
                  <p>CUPCAKES</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="mission_container">
            <div className="mission_content mission_image">
              <img src={commitment_img} />
            </div>
            <div className="mission_content">
              <div className="mission_text text_container">
                <h2>OUR COMMITMENT</h2>

                <span>
                  <p>
                    We have been inspired by having to get creative with
                    ingredients to bake around the dairy and egg intolerances
                    experienced by my youngest child. So, we have since made it
                    our ethos that all our clients should have a wide selection
                    (where possible) in the choice of cake sponge flavours and
                    fillings as we know how important this is. Whether you are
                    looking for a traditional sponge cake, egg free, vegan,
                    halal or gluten free, we will do our best to accommodate
                    your needs.
                  </p>
                </span>
                <span>
                  <p>
                    Our moist cakes are available in familiar classic flavours
                    such as vanilla and lemon, to a range of Asian inspired
                    combinations such as coconut and cardamom, chai masala, and
                    mango.
                  </p>
                </span>
                <span>
                  <p>
                    We aim to work alongside your décor company and the venue to
                    create the perfect centre piece - that is your cake. We look
                    at the latest trends in textures, colours and shapes to
                    design your cake. So, whether you want the design to include
                    your dress design details, colour scheme or the décor in
                    your venue, we make sure your cake is a bespoke fit for your
                    special day.
                  </p>
                </span>
                <button
                  className="brownBtn"
                  onClick={() => handleClick("about")}
                >
                  About Us
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </HelmetProvider>
  );
}
