import React, { useState } from "react";
import { FaInstagram, FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import "./Footer.css";
import { handleTermsClick } from "../../utils/termDoc";
import { handlePortionGuideClick } from "../../utils/portionGuide";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-top">
        <p>Email: purmilla@cakelayers.co.uk | Phone: 07904 966314</p>
      </div>
      <div className="footer-content">
        <div className="footer-info">
          <a
            href="#"
            onClick={handleTermsClick}
            style={{
              cursor: "pointer",
              textDecoration: "underline",
              color: "inherit",
            }}
          >
            Terms and Conditions
          </a>
          <a
            href="#"
            onClick={handlePortionGuideClick}
            style={{
              cursor: "pointer",
              textDecoration: "underline",
              color: "inherit",
            }}
          >
            Portion Guide
          </a>
        </div>
        <div className="footer-info">
          <p>&copy; {new Date().getFullYear()} Cake Layers</p>
        </div>
        <div className="footer-links">
          <a href="/">Home</a>
          <a href="/wedding-cakes">Wedding Cakes</a>
          <a href="/cake-ladoo">Cake Ladoo</a>
          <a href="/contact">Contact</a>
        </div>
        <div className="footer-social">
          <a
            href="https://www.instagram.com/cakelayersofficial/?hl=en"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram />
          </a>
          <a
            href="https://www.facebook.com/cakelayersofficial/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebookF />
          </a>
          <a
            href="https://www.linkedin.com/in/purmilla-bhatti-92b5a87b/?originalSubdomain=uk"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedinIn />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
