import "./WeddingCakesStyle.css";
import "./SharedProductsStyles.css";
import { useNavigate } from "react-router-dom";

import { useCakeContext } from "../../context/CakeContext";
import Cake from "../../components/productsComponents/ProductCard";
import { Helmet, HelmetProvider } from "react-helmet-async";

const WeddingCakes = () => {
  const navigate = useNavigate();
  const { cakes, loading, error } = useCakeContext();
  const weddingCakes = cakes.filter((cake) => cake.category === "wedding");
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;
  const handleClick = (link) => {
    navigate(`/${link}`);
  };
  return (
    <HelmetProvider>
      <Helmet>
        <title>Elegant Wedding Cakes | Cake Layers</title>
        <meta
          name="description"
          content="Explore our collection of elegant wedding cakes at Cake Layers. Discover exquisite designs and unmatched flavors for your special day."
        />
        <meta
          name="keywords"
          content="wedding cakes, elegant cakes, custom wedding cakes, Cake Layers, wedding cake designs"
        />
        <meta
          property="og:title"
          content="Elegant Wedding Cakes | Cake Layers"
        />
        <meta
          property="og:description"
          content="Explore our collection of elegant wedding cakes at Cake Layers. Discover exquisite designs and unmatched flavors for your special day."
        />
        {/* Replace with an appropriate image URL */}
        <meta
          property="og:url"
          content="https://cakelayers.web.app/wedding-cakes"
        />{" "}
        {/* Update with your actual URL */}
        <meta property="og:type" content="website" />
      </Helmet>
      <section>
        <div className="product_hero_container">
          <div className="product_hero_img_container text_container"></div>
          <h1>Elegant Wedding Cakes Portfolio</h1>
          <p id="hero_subtitle">
            Exquisite Designs and Unmatched Flavors for Your Special Day
          </p>
          <p id="hero_paragraph" className="wedding_hero">
            <span>
              Celebrate your special day with a wedding cake that embodies both
              elegance and exceptional taste. From classic
              <br /> buttercream finishes to intricate fondant details, our
              wedding cakes offer a perfect blend of beauty and flavour.
            </span>
            <span>
              Cake layers is proud to offer our wide range of flavours in a
              variety of options, including traditional, egg-free, vegan, halal
              <br />
              and (some) gluten-free sponge cakes. We provide classic favourites
              such as vanilla and lemon, as well as unique blends like chai
              <br />
              masala and mango which are inspired by our South Asian heritage.
              Each recipe is carefully crafted without the use of artificial
              <br />
              flavours, allowing the natural essence of our ingredients shine
              through.
            </span>
          </p>
          {/* <a className="hero_btn_link" onClick={() => handleClick("contact")}>
            Enquire
          </a> */}
          <button className="brownBtn" onClick={() => handleClick("contact")}>
            Enquire
          </button>
        </div>
      </section>
      <section id="product_gallery_cont">
        <div className="product_gallery">
          {weddingCakes.map((product) => (
            <Cake key={product.id} product={product} />
          ))}
        </div>
      </section>
    </HelmetProvider>
  );
};

export default WeddingCakes;
