import React, { useState, useEffect } from "react";
import { useCart } from "../../context/CartContext";
import { useLadoo } from "../../context/LadooContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaSyncAlt } from "react-icons/fa"; // Import the refresh icon

const LadooPreview = () => {
  const { addToCart } = useCart();
  const { flavors, toppings } = useLadoo(); // Access flavors, toppings from context
  const [selectedFlavor, setSelectedFlavor] = useState("");
  const [selectedTopping, setSelectedTopping] = useState(null); // Allow null for optional topping
  const [previewItems, setPreviewItems] = useState(Array(9).fill(null)); // Initialize with 9 empty slots
  const [ladoosCount, setLadoosCount] = useState(9); // Default to 9 ladoos
  const [flavorOptions, setFlavorOptions] = useState([]); // Flavor options based on ladoo count
  const [selectedFlavorsCount, setSelectedFlavorsCount] = useState(null); // Number of flavors to select

  useEffect(() => {
    // Update flavor options based on ladoo count
    switch (ladoosCount) {
      case 4:
        setFlavorOptions([4, 2, 1]);
        break;
      case 6:
        setFlavorOptions([6, 2, 1]);
        break;
      case 9:
        setFlavorOptions([9, 3, 1]);
        break;
      case 12:
        setFlavorOptions([12, 3, 1]);
        break;
      default:
        setFlavorOptions([]);
    }
  }, [ladoosCount]);

  const handleLadooCountChange = (count) => {
    setLadoosCount(count);
    setPreviewItems(Array(count).fill(null)); // Reset preview items based on new ladoos count
    setSelectedFlavorsCount(null); // Reset flavor selection when changing ladoos count
  };

  const handleFlavourCountChange = (option) => {
    setPreviewItems(Array(ladoosCount).fill(null));
    setSelectedFlavorsCount(option);
  };

  const calculateLadoosToAdd = () => {
    if (selectedFlavorsCount) {
      return ladoosCount / selectedFlavorsCount;
    }
    return 0;
  };

  const addToBox = () => {
    if (!selectedFlavorsCount) {
      toast.info("Please select the number of flavors!");
      return;
    }

    if (selectedFlavor) {
      const flavor = flavors.find((flavor) => flavor.id === selectedFlavor);
      const topping = selectedTopping
        ? toppings.find((topping) => topping.id === selectedTopping)
        : null;

      const ladoosToAdd = calculateLadoosToAdd();
      const newPreviewItems = [...previewItems];
      let addedCount = 0;

      // Add ladoos to the box based on ladoosToAdd
      for (let i = 0; i < newPreviewItems.length; i++) {
        if (newPreviewItems[i] === null && addedCount < ladoosToAdd) {
          newPreviewItems[i] = { flavor, topping };
          addedCount++;
        }
        if (addedCount === ladoosToAdd) break;
      }

      if (addedCount === 0) {
        toast.warn(
          "Ladoo Box is full or no slots available for the selected number of ladoos"
        );
      } else if (addedCount < ladoosToAdd) {
        toast.warn(
          "Not enough empty slots available for the selected number of ladoos"
        );
      }

      setPreviewItems(newPreviewItems);
    } else {
      toast.info("Please select a flavor!");
    }
  };

  const handleAddToCart = () => {
    if (previewItems.every((item) => item !== null)) {
      addToCart({ items: previewItems, type: "ladooBox" });
      toast.success("Items added to cart!");
      setPreviewItems(Array(ladoosCount).fill(null));
    } else {
      toast.warn("Please complete all slots before adding to the cart.");
    }
  };

  const handleRefreshBox = () => {
    setPreviewItems(Array(ladoosCount).fill(null)); // Clear the box
    toast.info("Box has been refreshed!");
  };

  return (
    <div className="product-container">
      {/* Ladoo Count Selection */}
      <div className="ladoos-count-selection ladoo_selection_txt_container txt_container">
        <h3>Cake Ladoo box size</h3>
        {[4, 6, 9, 12].map((count) => (
          <button
            key={count}
            className={`ladoos-button ${
              ladoosCount === count ? "selected" : ""
            }`}
            onClick={() => handleLadooCountChange(count)}
          >
            {count}
          </button>
        ))}
      </div>

      {/* Flavor Options Selection */}
      <div className="ladoos-count-selection ladoo_selection_txt_container txt_container">
        <h3>Choose Number of Flavors</h3>

        {flavorOptions.map((option) => (
          <button
            key={option}
            className={`ladoos-button ${
              selectedFlavorsCount === option ? "selected" : ""
            }`}
            onClick={() => handleFlavourCountChange(option)}
          >
            {option}
          </button>
        ))}
      </div>

      {/* Flavor Selection */}
      <div className="selection">
        <div className="ladoo_selection_txt_container txt_container">
          <h3>Choose Flavor</h3>
        </div>
        <div className="flavor-options">
          {flavors.map((flavor) => (
            <div key={flavor.id} className="flavor-item">
              <img
                src={flavor.image}
                alt={flavor.name}
                data-flavor={flavor.id}
                className={`flavor-image ${
                  selectedFlavor === flavor.id ? "selected" : ""
                }`}
                onClick={() => setSelectedFlavor(flavor.id)}
              />
              <p className="flavor-name">{flavor.name}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Topping Selection (Optional) */}
      <div className="selection">
        <div className="ladoo_selection_txt_container txt_container">
          <h3>Choose Topping (Optional)</h3>
          <p>Enhance Your Cake Ladoo with Extra Toppings!</p>
          <p>
            Our Cake Ladoos come with a standard drizzle to add a touch of
            sweetness, with the exception of the Coconut & Cardamom flavour,
            which is intentionally left without drizzle to allow its unique
            taste to shine through. If you’re looking to personalise your ladoo
            even further, you can choose from our selection of extra toppings,
            creating a truly indulgent treat that caters to your individual
            preferences.
          </p>
          <p id="ladoo_note">
            Please note that these additional toppings come at an extra cost.
          </p>
        </div>
        <div className="topping-options">
          {toppings.map((topping) => (
            <button
              key={topping.id}
              className={`topping-button ${
                selectedTopping === topping.id ? "selected" : ""
              }`}
              onClick={() => setSelectedTopping(topping.id)}
            >
              {topping.name}
            </button>
          ))}
          <button
            className={`topping-button ${
              selectedTopping === null ? "selected" : ""
            }`}
            onClick={() => setSelectedTopping(null)}
          >
            No Topping
          </button>
        </div>
      </div>

      {/* Add to Box Button */}
      <div className="add_btn_container">
        <div className="add_btn_sub_cont">
          <button className="pinkBtn" onClick={addToBox}>
            Add to Box
          </button>
        </div>
      </div>

      {/* Ladoo Grid inside a Cardboard Box Image */}
      <div className="preview_container">
        <div>
          <div className="sub_preview_container">
            <div className="cardboard-container">
              <div className={`ladoo-grid ladoos-${ladoosCount}`}>
                {previewItems.map((item, index) => (
                  <div
                    key={index}
                    className={`ladoo-box`}
                    data-index={index + 1}
                  >
                    {item ? (
                      <>
                        <img src={item.flavor.image} alt={item.flavor.name} />
                        <p>{item.flavor.name}</p>
                        {item.topping && <p>{item.topping.name}</p>}
                      </>
                    ) : null}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <button className="refresh-button" onClick={handleRefreshBox}>
            <FaSyncAlt />
          </button>
        </div>

        <div className="sub_preview_container preview_text txt_container">
          <p>
            Once the items are added to your cart, please submit an enquiry so
            we can review your request and get back to you. I will follow up to
            discuss your specific requirements and ensure everything is tailored
            to your needs.
          </p>
          <p>
            Note: If you would like to add further customisations, such as gold
            leaf or any other special requests, please let us know by filling
            out the contact form with your specific details.
          </p>
          <div className="add_btn_sub_cont">
            <button className="pinkBtn" onClick={handleAddToCart}>
              Add to Cart
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LadooPreview;
