import { useNavigate } from "react-router-dom";

import "./NoPageStyle.css";
export default function NoPage() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/home");
  };
  return (
    <div className="nopage_img_container">
      <div className="nopage_text">
        <h1>404</h1>
        <h3>Looks like this page is still baking!</h3>
        <p>Lets take you back home</p>
        <button onClick={handleClick}>Home</button>
      </div>
    </div>
  );
}
