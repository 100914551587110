import React from "react";
import aboutWeddingCakeImg from "../../assets/aboutWeddingCakeImg.jpg";
import aboutCelebrationCakeImg from "../../assets/aboutCelebrationCakeImg.jpg";
import aboutLadooImg from "../../assets/aboutLadooImg.jpg";
import aboutCorporateImg from "../../assets/aboutCorporateImg.jpg";
import aboutDessertImg from "../../assets/aboutDessertImg.jpeg";
import "./AboutStyle.css";
import { useNavigate } from "react-router-dom";
import about_img from "../../assets/about_hero_img.jpg";
export default function About() {
  const navigate = useNavigate();

  const handleClick = (path) => {
    navigate(path);
  };
  return (
    <>
      <section>
        <div className="about_hero_cont">
          <div className="about_img">
            <img src={about_img} />
          </div>
          <div className="about_txt text_container">
            <h1>Welcome to Cake Layers</h1>
            <div className="about_subtxt">
              <svg
                className="line-top"
                xmlns="http://www.w3.org/2000/svg"
                width="150"
                height="2"
                viewBox="0 0 150 2"
              >
                <line
                  x1="0"
                  y1="1"
                  x2="120"
                  y2="1"
                  stroke="#6a363b"
                  strokeWidth="2"
                />
              </svg>
              <p>Created by me, Purmilla</p>
            </div>

            <p>
              Cake Layers is an independent business created out of necessity
              when my son was unable to eat traditional cakes due to allergies
              to dairy and eggs. This was in 2003, before veganism had as much
              popularity as it does today. I had to take matters into my own
              hands and learn to adapt recipes so my son could enjoy cakes and
              desserts as much as his sisters. And so, the passion for baking
              grew from family birthday cakes to bespoke wedding cakes. We also
              offer a selection of desserts to compliment your cakes and our own
              creation and bespoke brand Cake Ladoo.
            </p>

            <button
              className="brownBtn"
              onClick={() => handleClick("/contact")}
            >
              CONTACT ME
            </button>
          </div>
        </div>
      </section>

      <section>
        <div className="about_hero_cont about_content_cont">
          <div className="about_img about_content_img">
            <img src={aboutWeddingCakeImg} alt="" />
          </div>
          <div className="about_txt about_content_txt text_container">
            <h2>Our Wedding Cakes</h2>
            <p>
              Cake layers is proud to offer our wide range of flavours in a
              variety of options, including traditional, egg-free, vegan, halal
              and (some) gluten-free sponge cakes. We provide classic favourites
              such as vanilla and lemon, as well as unique blends like chai
              masala and mango which are inspired by our South Asian heritage.
              Each recipe is carefully crafted without the use of artificial
              flavours, allowing the natural essence of our ingredients shine
              through.
            </p>
            <button
              className="about_content_btn"
              onClick={() => handleClick("/wedding-cakes")}
            >
              PORTFOLIO
            </button>
          </div>
        </div>

        <div className="about_hero_cont about_content_cont reverse_img">
          <div className="about_txt about_content_txt text_container">
            <h2>Our Celebration Cakes</h2>
            <p>
              Whether you desire a minimalist naked celebration cake, a creamy
              buttercream delight, or a show stopping centrepiece, we bring your
              vision to life. Our personalised cakes add the perfect touch of
              sweetness to life’s most cherished moments.
            </p>
            <button
              className="about_content_btn"
              onClick={() => handleClick("/celebration-cakes")}
            >
              PORTFOLIO
            </button>
          </div>
          <div className="about_img about_content_img">
            <img
              src={aboutCelebrationCakeImg}
              alt="An elegant two-tier cake adorned with delicate flower decorations, perfect for any celebration."
            />
          </div>
        </div>

        <div className="about_hero_cont about_content_cont">
          <div className="about_img about_content_img">
            <img
              src={aboutLadooImg}
              alt="An elegant round box filled with delicious ladoos, accompanied by a small see-through bag of ladoos on the side."
            />
          </div>
          <div className="about_txt about_content_txt text_container">
            <h2>Introducing Cake Ladoo</h2>
            <p>
              A unique fusion of tradition and innovation, crafted to add a
              luxurious touch to your celebrations. These exquisite sweets
              combine the richness of Eastern and western flavours with the
              elegance of modern dessert artistry, making them perfect for any
              occasion - from weddings and birthdays to festive gatherings and
              special events. Handcrafted from our signature egg-free cakes and
              delicious buttercreams, Cake Ladoo are a delightful experience in
              every bite. The most exciting thing about Cake Ladoo is that you
              get to choose from our continuously expanding flavour list and add
              a personalised message to your flavour card if you wish. Choose
              from a variety of gifting options, including boxed sweets, sugan
              trays, table favours, or stunning dessert displays. Every detail
              is crafted to make your gifting experience truly memorable,
              blending style, flavour, and personalisation into one sweet
              package.
            </p>
            <button
              className="about_content_btn"
              onClick={() => handleClick("/cake-ladoo")}
            >
              PORTFOLIO
            </button>
          </div>
        </div>

        <div className="about_hero_cont about_content_cont reverse_img">
          <div className="about_txt about_content_txt text_container">
            <h2>Our Corporate Cakes</h2>
            <p>
              Make your business partnerships memorable by gifting a corporate
              cake or cupcakes featuring your company logo and branding. A
              perfect gift to thank your suppliers and clients whilst promoting
              yourself. Also perfect for celebrating a company milestone,
              product launch, or team achievement.
            </p>
            <button
              className="about_content_btn"
              onClick={() => handleClick("/corporate")}
            >
              PORTFOLIO
            </button>
          </div>
          <div className="about_img about_content_img">
            <img
              src={aboutCorporateImg}
              alt="A sleek square cake designed for corporate celebrations, featuring a polished finish and subtle branding"
            />
          </div>
        </div>

        <div className="about_hero_cont about_content_cont">
          <div className="about_img about_content_img">
            <img
              src={aboutDessertImg}
              alt="A beautifully arranged display of a wide range of desserts, showcasing various flavors and textures in an elegant setting."
            />
          </div>
          <div className="about_txt about_content_txt text_container">
            <h2>Our Desserts</h2>
            <p>
              Our exquisite, handmade desserts are ideal for gifting, sharing
              with loved ones, or enhancing your event. These delicate, small
              delights are a fun and sweet treat for everyone to enjoy. Whether
              you’re looking for cupcakes, macarons, cakepops, cakesicles or
              mini chocolate Christmas puddings craft our desserts with care to
              perfectly complement your special occasion.
            </p>
            <button
              className="about_content_btn"
              onClick={() => handleClick("/desserts")}
            >
              PORTFOLIO
            </button>
          </div>
        </div>
      </section>
    </>
  );
}
