// src/context/CakeContext.js
import React, { createContext, useState, useEffect, useContext } from "react";
import {
  db,
  storage,
  collection,
  getDocs,
  ref,
  getDownloadURL,
} from "../firebase";

const CakeContext = createContext();

export const CakeProvider = ({ children }) => {
  const [cakes, setCakes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCakes = async () => {
      try {
        const cakesCollection = collection(db, "cakes");
        const cakeSnapshot = await getDocs(cakesCollection);
        const cakeList = await Promise.all(
          cakeSnapshot.docs.map(async (doc) => {
            const data = doc.data();
            const storageRef = ref(storage, data.main_image);
            const imageURL = await getDownloadURL(storageRef);
            return {
              id: doc.id,
              name: data.name,
              description: data.description,
              image: imageURL,
              category: data.category,
              order: data.order !== undefined ? data.order : Infinity,
            };
          })
        );
        cakeList.sort((a, b) => a.order - b.order);

        setCakes(cakeList);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCakes();
  }, []);

  return (
    <CakeContext.Provider value={{ cakes, loading, error }}>
      {children}
    </CakeContext.Provider>
  );
};

export const useCakeContext = () => {
  return useContext(CakeContext);
};
