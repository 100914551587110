import React, { useEffect, useState } from "react";
import {
  db,
  storage,
  collection,
  getDocs,
  ref,
  getDownloadURL,
} from "../../firebase";
import "./TestimonialStyle.css";
import TestimonialWithImage from "../../components/InformationComponents/TestimonialWithImage";
import TestimonialNoImage from "../../components/InformationComponents/TestimonialNoImage";
import { Helmet, HelmetProvider } from "react-helmet-async";

const Testimonials = () => {
  const [testimonialsWithImage, setTestimonialsWithImage] = useState([]);
  const [testimonialsNoImage, setTestimonialsNoImage] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const testimonialsCollection = collection(db, "testimonials");
        const testimonialsSnapshot = await getDocs(testimonialsCollection);

        const testimonialsList = await Promise.all(
          testimonialsSnapshot.docs.map(async (doc) => {
            const data = doc.data();
            let imageURL = null;

            // Check if the image is not null before fetching the URL
            if (data.image) {
              const storageRef = ref(storage, data.image);
              imageURL = await getDownloadURL(storageRef);
            }
            return {
              id: doc.id,
              name: data.name,
              description: data.description,
              image: imageURL,
              hasImage: data.has_image,
              order: data.order !== undefined ? data.order : Infinity,
            };
          })
        );

        const withImage = testimonialsList.filter(
          (item) => item.hasImage === true
        );
        const noImage = testimonialsList.filter(
          (item) => item.hasImage === false
        );

        withImage.sort((a, b) => a.order - b.order);
        noImage.sort((a, b) => a.order - b.order);

        setTestimonialsWithImage(withImage);
        setTestimonialsNoImage(noImage);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTestimonials();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <HelmetProvider>
      <Helmet>
        <title>Kind Words from Our Clients | Cake Layers</title>
        <meta
          name="description"
          content="Read heartfelt testimonials from our clients. Discover how Cake Layers has made special moments even more memorable with our custom cakes and desserts."
        />
        <meta
          name="keywords"
          content="client testimonials, Cake Layers reviews, customer feedback, cake testimonials, dessert reviews"
        />
        <meta
          property="og:title"
          content="Kind Words from Our Clients | Cake Layers"
        />
        <meta
          property="og:description"
          content="Read heartfelt testimonials from our clients. Discover how Cake Layers has made special moments even more memorable with our custom cakes and desserts."
        />
        <meta
          property="og:url"
          content="https://cakelayers.web.app/testimonials"
        />{" "}
        {/* Update with your actual URL */}
        <meta property="og:type" content="website" />
        {/* Optionally include an image if you have one */}
        {/* <meta property="og:image" content="https://example.com/path/to/image.jpg" /> */}
      </Helmet>
      <section>
        <div className="testimonial_title text_container">
          <h1>Kind Words</h1>
          <p>
            "Your kind words fuel our passion and creativity, and we are
            profoundly grateful for your trust in us to make your special
            moments extraordinary.
            <br />
            Each testimonial serves as a reminder of why we pour our hearts into
            our work, striving to craft something truly memorable for every
            occasion."
          </p>
        </div>
      </section>
      <section>
        <div>
          {testimonialsWithImage.map((testimonial) => (
            <TestimonialWithImage
              key={testimonial.id}
              testimonial={testimonial}
            />
          ))}
        </div>
      </section>

      <section>
        <div className="testimonial_no_img">
          {testimonialsNoImage.map((testimonial) => (
            <TestimonialNoImage
              key={testimonial.id}
              testimonial={testimonial}
            />
          ))}
        </div>
      </section>
    </HelmetProvider>
  );
};

export default Testimonials;
