// src/context/LadooContext.js
import React, { createContext, useContext, useEffect, useState } from "react";
import {
  db,
  storage,
  collection,
  getDocs,
  ref,
  getDownloadURL,
} from "../firebase";

// Create the context
const LadooContext = createContext();

// LadooProvider component that wraps around the app
export const LadooProvider = ({ children }) => {
  const [flavors, setFlavors] = useState([]);
  const [toppings, setToppings] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch flavors from Firestore
        const flavorsCollection = collection(db, "ladoo_product_info");
        const toppingsCollection = collection(db, "ladoo_toppings_info");

        const flavorsSnapshot = await getDocs(flavorsCollection);
        const toppingsSnapshot = await getDocs(toppingsCollection);

        // Process flavors
        const flavorList = await Promise.all(
          flavorsSnapshot.docs.map(async (doc) => {
            const data = doc.data();
            const storageRef = ref(storage, data.image);
            const imageURL = await getDownloadURL(storageRef);

            return {
              id: doc.id,
              name: data.name,
              image: imageURL,
              price: data.price,
            };
          })
        );

        // Process toppings
        const toppingList = toppingsSnapshot.docs.map((doc) => ({
          id: doc.id,
          name: doc.data().name,
          price: doc.data().price,
        }));

        flavorList.sort((a, b) => a.name.localeCompare(b.name));
        toppingList.sort((a, b) => a.name.localeCompare(b.name));

        setFlavors(flavorList);
        setToppings(toppingList);
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <LadooContext.Provider value={{ flavors, toppings, loading }}>
      {children}
    </LadooContext.Provider>
  );
};

// Custom hook for accessing the context
export const useLadoo = () => useContext(LadooContext);
