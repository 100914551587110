import React, { useState } from "react";
import "react-lazy-load-image-component/src/effects/blur.css";
import "./ProductCardStyle.css";
import { useCart } from "../../context/CartContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ProductCard({ product }) {
  const [isHovered, setIsHovered] = useState(false);
  const { addToCart } = useCart();

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const handleAddToCart = () => {
    addToCart({ items: product, type: "galleryImage" });
    toast.success("Image added to cart!");
  };

  return (
    <div
      className="product-card"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {/* Lazy load the image with a grey placeholder */}
      <img
        id="cake_img"
        src={product.image}
        alt={product.description}
        className={`product-image ${isHovered ? "hovered" : ""}`}
        loading="lazy"
      />

      {isHovered && (
        <div className="card_overlay">
          <button className="add-to-cart-btn" onClick={handleAddToCart}>
            Add to Cart
          </button>
        </div>
      )}
    </div>
  );
}

export default ProductCard;
