import LadooPreview from "../../components/ladooPreviewComponent/LadooPreview";
import { Helmet, HelmetProvider } from "react-helmet-async";
import "./CakeLadooStyle.css";

const CakeLadoo = () => {
  return (
    <HelmetProvider>
      <section>
        <div className="product_hero_container celebration_txt_cont">
          <h1>Cake Ladoo Ordering</h1>
          <p id="hero_paragraph">
            <br />
            We have created the perfect gifting experience when ordering our
            bespoke Cake Ladoo boxes. Choose the size of your box, add your
            favourite flavours from our continuously expanding flavour list and
            add a personalised message if your wish.
            <br />
            <br />
            Other gifting and display options, include sugan trays, table
            favours, and dessert tables. Every detail is crafted to make your
            event truly memorable, blending style, flavour, and personalisation
            into one sweet package.
          </p>
        </div>
      </section>
      <section>{<LadooPreview />}</section>
    </HelmetProvider>
  );
};

export default CakeLadoo;
