import React, { useState, useEffect } from "react";
import { db, collection, getDocs } from "../../firebase";
import { useLadoo } from "../../context/LadooContext";
import Flavour from "../../components/productsComponents/Flavour";
import flavourWeddingCakeImg from "../../assets/flavour_wedding_cake_img.jpg";
import flavourLadooImg from "../../assets/flavour_ladoo_img.jpg";
import flavourDessertImg from "../../assets/flavourDessertImg.jpg";
import flavourCupcakeImg from "../../assets/flavour_cupcake_img.jpg";
import { Helmet, HelmetProvider } from "react-helmet-async";

import "./FlavourInformationStyle.css";

export default function FlavourInformation() {
  const [weddingFlavours, setWeddingCakeFlavours] = useState([]);
  const [fillingFlavours, setFillingFlavours] = useState([]);
  const [buttercreamFlavour, setButtercreamFlavour] = useState([]);
  const [dessertFlavours, setDessertFlavours] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Getting ladoo flavors and toppings from LadooContext
  const { flavors, toppings } = useLadoo();

  useEffect(() => {
    const fetchCakes = async () => {
      try {
        const flavoursCollection = collection(db, "other_flavours");
        const flavourSnapshot = await getDocs(flavoursCollection);

        const flavourList = flavourSnapshot.docs.map((doc) => ({
          id: doc.id,
          name: doc.data().name,
          type: doc.data().type,
        }));

        // Filter the flavours based on their type
        const wCakeFlavours = flavourList
          .filter((item) => item.type === "weddingCakes")
          .sort((a, b) => a.name.localeCompare(b.name));
        const dessertFlavours = flavourList
          .filter((item) => item.type === "desserts")
          .sort((a, b) => a.name.localeCompare(b.name));
        const fillingFlavours = flavourList
          .filter((item) => item.type === "fillings")
          .sort((a, b) => a.name.localeCompare(b.name));
        const buttercreamFlavour = flavourList
          .filter((item) => item.type === "buttercreams")
          .sort((a, b) => a.name.localeCompare(b.name));

        // Set the state with the filtered flavour lists
        setWeddingCakeFlavours(wCakeFlavours);
        setFillingFlavours(fillingFlavours);
        setButtercreamFlavour(buttercreamFlavour);
        setDessertFlavours(dessertFlavours);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCakes();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <HelmetProvider>
      <Helmet>
        <title>Discover Our Delicious Flavours | Cake Layers</title>
        <meta
          name="description"
          content="Explore the wide range of delicious flavors offered by Cake Layers. From wedding cakes to cupcakes and desserts, find the perfect taste for your celebration."
        />
        <meta
          name="keywords"
          content="cake flavors, wedding cakes, cake ladoo, celebration cakes, cupcakes, desserts, Cake Layers, toppings"
        />
        <meta
          property="og:title"
          content="Discover Our Delicious Flavors | Cake Layers"
        />
        <meta
          property="og:description"
          content="Explore the wide range of delicious flavors offered by Cake Layers. From wedding cakes to cupcakes and desserts, find the perfect taste for your celebration."
        />
        <meta property="og:url" content="https://cakelayers.web.app/flavors" />{" "}
        {/* Update with your actual URL */}
        <meta property="og:type" content="website" />
        {/* Optionally include an image if you have one */}
        {/* <meta property="og:image" content="https://example.com/path/to/image.jpg" /> */}
      </Helmet>
      <section id="flavour_section">
        <div className="flavour_img_container ">
          <div className="flavour_hero_txt text_container">
            <h1>
              Discover Our
              <br />
              Delicious Flavours
            </h1>
            <p>
              "Flavors that inspire, creations that delight.
              <br />
              Your celebration deserves a taste as unforgettable as the moment
              itself"
            </p>
          </div>
        </div>
        <div className="flavour_container">
          <div className="flavour_content">
            <div className="flavour_image">
              <img
                src={flavourWeddingCakeImg}
                alt="A stunning five-tier white cake displayed on a round table in a beautifully decorated party hall."
              />
            </div>
            <div className="flavour_txt text_container">
              <h2>Wedding Cakes</h2>
              <div className="flavour_grid">
                {weddingFlavours.map((flavour) => {
                  return <Flavour key={flavour.id} flavour={flavour} />;
                })}
              </div>
            </div>
          </div>
          <div className="flavour_content">
            <div className="flavour_image">
              <img
                src={flavourLadooImg}
                alt="A large assortment of ladoos showcasing nine different flavors, all beautifully aligned."
              />
            </div>

            <div className="flavour_txt text_container">
              <h2>Cake Ladoo</h2>
              <div className="flavour_grid">
                {flavors.map((flavour) => {
                  return <Flavour key={flavour.id} flavour={flavour} />;
                })}
              </div>
              <h2 id="topping_title">Toppings</h2>
              <div className="flavour_grid">
                {toppings.map((flavour) => {
                  return <Flavour key={flavour.id} flavour={flavour} />;
                })}
              </div>
            </div>
          </div>

          <div className="flavour_content">
            <div className="flavour_image">
              <img
                src={flavourDessertImg}
                alt="A beautiful golden tray adorned with white macarons and four elegantly decorated cupcakes."
              />
            </div>
            <div className="flavour_txt text_container">
              <h2>Desserts</h2>
              <div className="flavour_grid">
                {dessertFlavours.map((flavour) => {
                  return <Flavour key={flavour.id} flavour={flavour} />;
                })}
              </div>
            </div>
          </div>

          <div className="flavour_content fillings_buttercreams">
            <div className="flavour_txt text_container fillings_buttercreams_content">
              <h2>Fillings</h2>
              <div className="flavour_grid">
                {fillingFlavours.map((flavour) => {
                  return <Flavour key={flavour.id} flavour={flavour} />;
                })}
              </div>
            </div>
            <div className="flavour_txt text_container fillings_buttercreams_content">
              <h2>Buttercreams</h2>
              <div className="flavour_grid">
                {buttercreamFlavour.map((flavour) => {
                  return <Flavour key={flavour.id} flavour={flavour} />;
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </HelmetProvider>
  );
}
