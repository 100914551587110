import "./PricingInformationStyle.css";
import pricingWeddingImg from "../../assets/pricingWeddingImg.jpg";
import pricingCelebrationImg from "../../assets/pricingCelebrationImg.jpg";
import pricingLadooImg from "../../assets/pricingLadooImg.jpg";
import pricingCupcakesImg from "../../assets/pricingCupcakesImg.jpg";
import pricingDessertImg from "../../assets/pricingDessertImg.jpg";
import { handlePortionGuideClick } from "../../utils/portionGuide";
import { Helmet, HelmetProvider } from "react-helmet-async";

export default function FlavourInformation() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Elegant Cake Designs & Pricing | Cake Layers</title>
        <meta
          name="description"
          content="Explore our custom cake pricing for weddings, celebrations, and desserts. Cake Layers offers exquisite designs with personalized touches."
        />
        <meta
          name="keywords"
          content="cake pricing, wedding cakes, celebration cakes, custom cakes, cake ladoo, cupcakes, dessert pricing"
        />
        <meta
          property="og:title"
          content="Elegant Cake Designs & Pricing | Cake Layers"
        />
        <meta
          property="og:description"
          content="Explore our custom cake pricing for weddings, celebrations, and desserts. Cake Layers offers exquisite designs with personalized touches."
        />
        <meta property="og:url" content="https://cakelayers.web.app/pricing" />{" "}
        <meta property="og:type" content="website" />
      </Helmet>
      <section>
        <div className="product_hero_container text_container price_hero">
          <h1>Price Guide</h1>
          <p id="hero_paragraph">
            <br />
            We specialise in custom-designed cakes for your special occasions,
            whether it's a wedding, birthday, or corporate event.
            <br />
            We work closely with you to ensure every detail, from the delightful
            flavours to the intricate decorations, aligns with your vision.
            <br />
            <br />
            Pricing varies based on the chosen flavour, finish, and level of
            detail, guaranteeing that your cake is visually stunning and
            delicious.
            <br />
            Let us help you create a memorable centrepiece for your celebration!
          </p>

          <button className="brownBtn" onClick={handlePortionGuideClick}>
            View Portion Guide
          </button>
        </div>
      </section>

      <section>
        <div className="pricing_outr_container">
          <div className="pricing_container">
            <div className="pricing_img">
              <img
                src={pricingWeddingImg}
                alt="An elegant two-tier cake adorned with delicate white flower decorations."
              />
            </div>
            <div className="pricing_txt text_container">
              <h2>Wedding Cake</h2>
              <p>
                The price of your wedding cake will take into consideration the
                flavour/s, number of actual cake tiers, dummy tiers, design
                detail and delivery distance. The following prices are a guide
                price for a wedding cake made of all actual cake tiers.
              </p>
              <ul>
                <li>
                  <p>3-Tier (10/8/6”) wedding cake - £650</p>
                </li>
                <li>
                  <p>4-tier (10/8/6/4”) wedding cake - £800</p>
                </li>
                <li>
                  <p>5-tier (12/10/8/6/4”) wedding cake - £1250</p>
                </li>
              </ul>
              <p>
                Standard local delivery charge £35 for travel within 5 miles.
                There is an additional £1 per mile charge to your venue.
              </p>
            </div>
          </div>

          <div className="pricing_container">
            <div className="pricing_img">
              <img
                src={pricingLadooImg}
                alt="A beautiful box containing nine ladoos, each crafted with care and vibrant colors."
              />
            </div>
            <div className="pricing_txt text_container">
              <h2>Cake Ladoo</h2>
              <div className="pricing_grid">
                <div>
                  <span>
                    <p>4 Cake Ladoo (Coming soon)</p>
                  </span>
                  <p>1 flavour - £5.00</p>
                  <p>2 flavours - £5.50</p>
                  <p>4 flavours - £6.50</p>
                  <p>Minimum order 40 boxes</p>
                </div>
                <div>
                  <span>
                    <p>6 Cake Ladoo (Coming soon)</p>
                  </span>
                  <p>1 flavour - £6.50</p>
                  <p>2 flavours - £8.00</p>
                  <p>6 flavours - £9.00</p>
                  <p>Minimum order 30 boxes</p>
                </div>
                <div>
                  <span>
                    <p>9 Cake Ladoo</p>
                  </span>
                  <p>1 flavour - £8.50</p>
                  <p>3 flavours - £11.00</p>
                  <p>9 flavours - £15.00</p>
                  <p>Minimum order 20 boxes</p>
                </div>
                <div>
                  <span>
                    <p>12 Cake Ladoo (Coming soon)</p>
                  </span>
                  <p>4 flavours - £13.50</p>
                  <p>12 flavours - £22.00</p>
                  <p>Minimum order 10 boxes</p>
                </div>
              </div>

              <span>
                <p>Additional toppings are charged per Cake Ladoo</p>
              </span>

              <ul>
                <li>
                  <p>Gold leaf - 50p</p>
                </li>
                <li>
                  <p>Sprinkles - 25p</p>
                </li>
                <li>
                  <p>Small sugar decorations - flowers, bows, hearts, - 50p</p>
                </li>
                <li>
                  <p>
                    Nuts - chopped toasted almonds, pistachios, hazelnuts - 50p
                  </p>
                </li>
                <li>
                  <p>Initials. - £1.00</p>
                </li>
                <li>
                  <p>Personalised message per box - £1.00</p>
                </li>
              </ul>
              <span>
                <p>
                  9 Flavour Taster Box: £20 - no nuts, and from £25 - with nuts
                </p>
              </span>
            </div>
          </div>

          <div className="pricing_container">
            <div className="pricing_img">
              <img
                src={pricingCelebrationImg}
                alt="A stunning pink round birthday cake adorned with various makeup items on top, creating a glamorous celebration theme."
              />
            </div>
            <div className="pricing_txt text_container">
              <h2>Celebration Cake</h2>
              <p>Sizes start from 5”</p>
              <ul>
                <li>
                  <p>Buttercream finish - £90</p>
                </li>
                <li>
                  <p>Sugar paste icing - £110</p>
                </li>
              </ul>
              <p>
                Delivery available – charges apply (tiered cakes are delivery
                only).
              </p>
            </div>
          </div>

          <div className="pricing_container">
            <div className="pricing_img">
              <img
                src={pricingCupcakesImg}
                alt="Stunning cupcakes topped with smooth white buttercream and vibrant, colorful sprinkles, perfect for any festive occasion."
              />
            </div>
            <div className="pricing_txt text_container">
              <h2>Cupcakes</h2>

              <p>Minimum order 12 cupcakes £48</p>
              <p>Buttercream swirls - from £42</p>
              <p>Edible toppers - from £54</p>
              <p>Sugar paste fondant domes - from £60</p>
            </div>
          </div>

          <div className="pricing_container">
            <div className="pricing_img">
              <img
                src={pricingDessertImg}
                alt="An elegant tower showcasing two flavors of macarons, beautifully arranged for a sophisticated touch at any event."
              />
            </div>
            <div className="pricing_txt text_container">
              <h2>Desserts</h2>
              <p>
                There is a minimum order per dessert option. Prices do not
                include packaging
              </p>
              <ul>
                <li>
                  <p>Cake Pops - £4.50 each (minimum order 30)</p>
                </li>
                <li>
                  <p>Cakesicles - £5.50 each (minimum order 30)</p>
                </li>
                <li>
                  <p>Macarons - £2.00 each (minimum order 50)</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </HelmetProvider>
  );
}
